const localKey = 'kmks_username'

const TokenKey = "KmksToken"

const getToken = () => {
  return localStorage.getItem(TokenKey)
}

const  getUserName = () =>{
    return localStorage.getItem(localKey)
}

const setToken = (token) => {
  return localStorage.setItem(TokenKey , token)
}

const setUserName =(name) =>{
    return localStorage.setItem(localKey , name)
}

const  clearLocalStorage =()=> {
    return localStorage.clear()
}

// 判断移动端设备
const isMobile = () => {
    let flag =  navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
   
    return flag
    
}

// 判断用户是否登录

const IsUserToken = () => {
  const token = localStorage.getItem('KmksToken');
  return !!token;  // 如果 token 存在且不为空，则返回 true，否则返回 false
}

const IsLoginIn = (router) => {
  const token = localStorage.getItem('KmksToken') 
  if (!token) {
    // 未登录  重定向到登录页
    router.push({
      path: '/login',
      query: { redirect : window.location.pathname}
    })

    return true  // 已重定向

  }

  return false  // 未重定向


}



const GenerateUUID = () =>{
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    )
  }

export default {
    getToken,
    getUserName,
    setToken,
    setUserName,
    clearLocalStorage,
    isMobile,
    GenerateUUID,
    IsUserToken,
    IsLoginIn
}
 