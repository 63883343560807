<template>
  <div class="mobile-layout-box">
    <div class="top-box fixed-top">
      <TopNavBar />
    </div>
    <div class="mobile-content">
      <keep-alive>
        <router-view :key="$route.path" v-if="$route.meta.IsMobile"></router-view>
        </keep-alive>
      
    </div>
    <div class="mobile-bottom-footer">
      <BlackbgFooter/>
    </div>
  </div>
</template>

<script>
import TopNavBar from "./Comps/TopNavBar.vue";
import BlackbgFooter from "@/components/BlackbgFooter.vue";

export default {
  components: {
    TopNavBar,
    BlackbgFooter
  },
  data() {
    return {};
  },
};
</script>

<style scoped>



.fixed-top {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  z-index: 2000; /* 确保元素在其他内容之上 */
}


.mobile-content {
  margin-top:64px;
  /* background-color: #000; */

}




</style>
