// 创建文件en/index.js
const en = {
  system: "AIGC PLATFORM",
  WebName: "AIGC PLATFORM",
  Demo: "Demo",
  theme: "Video 2 Video",
  TipsText:
    "Beijing, the capital of ‌ People's Republic of China, is located in north China. It is a famous cultural city with a history of more than 3000 years.",

  firstIndex: "Home",
  language: "Chinese",
  slideModule: {
    warnText: "Please check the slider first",
    slideSuccess:"Check pass",
    clickText: "Click the button to verify",
    direction:"Slide right"
  },
  randomModule: {
    randomText:"Random code",
    randomWarn:"Please enter 4 random digits",
    randomCorrectWarn:"Please enter the correct random code"
  },
  Error: {
    network: "The network connection is down. Please try again later",
    NoneTigs: "No task",
    NoneDownFile: "No resources can be saved",
    EnterCopyFile: "Please enter the text you want to copy",
    exchangeFile: "Please enter the text you want to convert first",
    inputName: "Please fill in the name first",
    uploadFile: "Please upload or record the file first",
    FailNetwork: "Request failed",
    limitCount: "Files are uploaded not more than 5 times",
    AudioPermission: "Unable to obtain audio device",
    Recording:"Recording generation",
    timeout:"Network timeout, please try again later",
    CloneVoiceError:"Failed to generate, please check language"
  },
  
  DialogModel: {
    PublicMediaLibrary: "Public Media Library",
    ChooseTitle: "Choose",
    Cancel: "Cancel",
    Sumit: "Confirm",
    Userdefined: "User Defined",
    Preview: "Preview",
    CancelPreview: "Cancel Preview",
    exchangeFileSuccess:
      "If the conversion is successful, you can listen and save",
  },
  FileAction: {
    Cancel: "Delete",
    Download: "Download",
    Loading: "Loading",
    incorrectType: "The uploaded file format is incorrect",
    limitSize: "Upload file size cannot exceed 200MB!",
    limit10MSize:"Upload file size cannot exceed 10MB!",
    AllowFile:
      "Only image is allowed to be uploaded",
    AllowNotImageFile: "Only PDF, DOC, DOCX, TXT, Excel or CSV files are allowed to be uploaded",
    loginTips: "Login in first",
    TipsTitle: "Tips",
    deleteSuccess: "Successfully deleted",
    failDeleted: "Deletion failure",
    cancelDelete: "Undelete",
    delete: "Delete",
    SaveSuccess: "Successfully save",
    ExitPrompt: "The account has been logged in, please log out first!",
    UserLoggedOut: "User logged out",
    RequestFailed: "Request Failed",
    ConfirmationPrompt: "Make sure you want to delete the file",
    CustomScene: "Custom Scene",
    UploadScene: "Upload your scene",
    CopySuccess: "Successfully copy",
    deleteChatTitle: "Delete the session?",
    deleteWarning:"After being deleted, it cannot be restored!",
    Confirm:"Confirm",
    Undelete:"Cancel"
  },
  Setting: {
    Profile: "Profile",
    Password: "Password",
  },
  Price: {
    Subscription: "Subscription",
    Free: "Free",
    FreePrice:"Free",
    BuyBtn:"Buy",
    Pro: "Pro",
    EnterPrice: "Enter Price",
    FreeInfo: {
      title01: "1 custom digital person free edition",
      desc01: "Each card can be modified once",
      title02: "10 computing power",
      desc02: "Approximately 10 seconds of video generation time",
    },
    ProInfo: {
      title01: "3 custom digital people free edition",
      desc01: "Each card can be modified three times",
      title02: "3600 computing power",
      desc02: "Approximately equal to 60 minutes of video generation time",
      title03: "1000+ public number people",
      desc03: "Digital people are constantly updated",
      title04: "150+ voice and voice acting",
      desc04: "The language and voice acting are constantly updated",
    }
  },
  topMenu: {
    menu01: "product",
    menu02: "introduction",
    menu03: "Price",
  },
  login: "login",
  register: "register",
  siderMenu: {
    Video2Video: "Video2Video",
    AIWritting: "AI Writting",
    AIvoice: "AI Voice",
    AICharacter: "AI Character",
    VideoMake: "Video2Video",
    Customized: "Customized",
    AIAvatar: "AI Avatar",
    Live: "Live",
    LiveStream: "Live Stream"
  },
  AIWritting: {
    title: "AI Chat or Writing",
    modelSelect: "Model Selection",
    select: "Select",
    inputText: "Input Text",
    send: "Send",
    firstwords: "Hello, can I help you?",
    OpenChat:"Start a new conversation",
    ContinueChat: "Continue the conversation",
    NewConversation:"New Conversation",
    Tips:"Big models can make mistakes. Please check important information.",
  },
  AIVoice: {
    ThemeTitle: "AI Voice",
    TexttoSpeech: "Text-to-Speech",
    VoiceSelection: "Voice Selection",
    NoneTigs: "No, please go to the right side to customize the clone",
    NoneMobileTigs: "No custom voice . Go to the 'Voice Clone'",
    CustomVoice: "Custom Voice",
    SelectVoice: "Select Appropriate Voice",
    EnterContent: "Please Enter Content",
    ClickConversion: "One-click Conversion",
    Audition: "Audition",
    Listening: "Listening",
    Download: "Download",
    CloudStorage: "Cloud Storage",
    CloneVoice: "Clone Voice",
    GiveName: "Please give the file a name first",
    UploadFile: "Upload Video or Voice",
    RapidCloning: "Rapid Cloning",
    SelectTextListen: "Select text first and then listen",
    Samples: "Voice Clonning Samples",
    Male: "Male",
    Female: "Female",
    Original: "Original",
    Cloned: "Cloned",
    AnalysisAndTranslation: "Video Speech Analysis and Translation",
    SpeechToText: "Speech-to-Text",
    HereSpeechToText: "Here's speech to text",
    Copy: "One-click Replication",
    TranslatedText: "Translated Text",
    UploadVoiceBtn: "Upload Voice",
    OneselfVoice: "Cloned Oneself Voice",
    Preview: "Preview",
    RandomBtn: "Random Cases",
    SaveTips: "Whether the generated audio is satisfactory or not?",
    saveBtn: "Save",
    cancelBtn: "Cancel",
    UploadTextTigs: "Upload a voice or video",
    warnText:"Upload clean audio and video in the 30s for the best effect",
    defaultText:
      "Beijing, the capital of ‌ People's Republic of China, is located in north China. It is a famous cultural city with a history of more than 3000 years.",
  },

  AICharacter: {
    title: "Pic 2 Video",
    AvatarTitle: "AI Avatar",
    AiAudio: "AI Audio",
    ImportVoice: "Import Voice",
    UploadVoice: "Upload Voice",
    UploadVoiceText: "Upload Your Voice",
    FaceText: "Upload a picture with a face",
    PlaceholderText: "Please select an appropriate voice",
    EmptyTips: "No audio is available , Customize the audio first",
    SelectAvatar: "Select Avatar",
    CustomAvatar: "Custom Avatar",
    ClickBtn: "Click to Select",
    ShowTips:
      "Limited to 5 second videos, you can use it to customize longer voice videos in ",
    GenerationClick: "One-Click Generation",
    DeleteFile: "Delete",
    DownLoadFile: "DownLoad",
    modelName: " 'Video2Video' Model",
  },

  AIVideoModel: {
    title: "Video 2 Video",
    AiAudio: "AI Audio",
    ImportVoice: "Import Voice",
    UploadVoice: "Upload Voice",
    SelectVideo: "Select Video",
    CustomizedVideo: "Customized Video",
    chooseBtn: "Click to Select",
    UploadText: "Upload a voice messag",
    UploadVideoText: "Upload a video messag",

    ShowTips:
      "Your video will be displayed here, please click the button below to do so Generate",
    GenerationClick: "One-Click Generation",
    DeleteFile: "Delete",
    DownLoadFile: "DownLoad",
    SaveFileBtn: "Cloud Save",
  },

  LoginModel: {
    EmailLogin: "Email Login",
    PhoneLogin: "Mobile Login",
    UsernameOrEmail: "Email",
    password: "Password",
    Submit: "Submit",
    Reset: "Reset",
    PhoneNumber: "Phone Number",
    VerificationCode: "Verification Code",
    Sendcode: "Send Code",
    GoRegister: "To Register",
    Forgotpassword: "Forgot Password",
    Goback: "Back to Home",
    passwordTigs: "Please enter password",
    phoneTigs: "Please  enter your phone number",
    codeTigs: "Please enter the verification code",
    userTigs: "Please enter your username",
    sendTigs: "Send Failure",
  },

  RegisterModel: {
    EmailRegister: "Email Registration",
    placeholderText: "Please enter email",
    Username: "Username",
    password: "Password",
    PhoneRegister: "Mobile number registration",
    PhoneNumber: "Phone Number",
    VerificationCode: "Verification Code",
    Sendcode: "Send Code",
    Register: "Register",
    Reset: "Reset",
    GoLogin: "Go Login",
    Goback: "Back to Home",
    CorrectEmailFormat: "Please enter the correct email format",
    passwordTigs: "Please enter password",
    phoneTigs: "Please  enter your phone number",
    codeTigs: "Please enter the verification code",
    userTigs: "Please enter your username",
    sendTigs: "Send Failure",
    successTigs: "Registered Successfully!",
  },

  FindPassModel: {
    themeTitle: "Password",
    Email: "Email",
    SendCode: "Send Code",
    VerificationCode: "Verification Code",
    NewPassword: "New Password",
    ConfirmPassword: "Confirm Password",
    Submit: "Submit",
    Reset: "Reset",
    EnterEmailText: "Please enter email",
    CorrectEmailFormat: "Please enter the correct email format",
    passwordTigs: "Please enter password",
    NewPasswordTigs: "Please enter your confirmation password",
    codeTigs: "Please enter the verification code",
    GoLogin: "Go Login",
    Goback: "Back to Home",
    SuccessfulSetting: "The Settings are successful. Log in again",
  },

  MediaLibrary: {
    themeTitle: "User Media Library",
    AudioData: "Audio Data",
    VideoData: "Video Data",
    PictureData: "Picture Data",
  },

  full: "full-screen display",
  account: "myAccount",
  invoice: "invoice",
  reconciliation: "Statement",
  record: "recording",
  report: "report",
  setting: "Settings",

  tips: "{name} Username and password are filled in casually {cont}",
  administrator: "administrator",
  placeUser: "please enter user name",
  palcePass: "Please enter your password",
  palceCode: "please enter verification code",
  accounts: "accounts",
  password: "password",
  code: "Verification code",
  footer: {
    CompanyProfile: "Company Profile",
    introduction: "Company Introduction",
    UserAgreement: "User Agreement",
    Privacy: "Privacy Agreement",
    Aboutus: "About Us",
    ContactUs: "Contact Us",
    CustomerService: "Customer Service",
    TechnicalSupport: "Technical Support",
  },

  Table: {
    View: "View",
    Download: "Download",
    Delete: "Delete",
    Preview: "Preview",
    Time: "Time",
    label: "Item",
    Operation: "Operation",
    ImageToVideoTitle: "AI Video Recording",
    VideoHistoryTitle: "Video Generation Recording",
    ImageHistoryTitle: "Picture Management",
    VoiceCloneHistoryTitle: "Cloned Voice Data",
    VoiceName: "Name",
    TTSSpeech: "TTS",
    Playing: "Playing",
    Play: "Play",
    NoMoreData: "No More Data",
  },
  Company: {
    title: "Company Introduction:",
    desc01:
      "Kamikasi Tech Ltd. is a pioneering AI startup known for its innovation and creativity. As an official member of the NVIDIA Inception Program, we are committed to advancing AI technologies.",
    desc02:
      "Founded in July 2022 by entrepreneur Gu Xing and senior AI expert Chen Ping, along with a team of talented professionals, Kamikasi Tech focuses on the research, development, and commercialization of specialized digital humans and Artificial Intelligence Generated Content (AIGC) for the media production industry.",
    desc03:
      "We offer comprehensive solutions for the creation, application, and management of interactive digital avatars, catering to both enterprises and individuals. By harnessing state-of-the-art AI technology, Kamikasi Tech has made significant strides in developing hyper-realistic, intelligent digital humans capable of autonomously performing tasks driven by AI models. These advancements open up new horizons across sectors such as entertainment, education, and beyond",
    desc04:
      "With a vision to become a global leader in AI digital human development and operation, Kamikasi Tech has trained and deployed hundreds of AI models, continuously pushing the boundaries of innovation. Our mission is to enhance the synergy between AI and creativity, delivering unmatched experiences to users and reshaping the future of media industries.",
  },

  AccountSetting: {
    title: "Account Setting",
    ChangeUserName: "Modifying User Name",
    UserName: "User Name",
    NewName: "New User Name",
    newnameWarning: "The new user name cannot be empty",
    Submit: "Submit",
    Reset: "Reset",
    ChangeEmail: "Modify Email",
    Email: "Email",
    NewEmail: "New Email",
    SendCode: "Send Code"
  }

};
export default en;
