//  websocket 9952 端口
export const WebsocketPort9952 = 'wss://kamikasi.net/websocket/rtc/9952/'
// 公共ip
export const WebsocketIp = 'wss://kamikasi.net/websocket/rtc/'


// 8种视频格式
export const videoTypeList = ['.avi', '.mp4', '.mov','.wmv','.flv', '.mpeg','.webm']


// 音频格式
export const audioTypeList = ['.mp3', '.wav', '.wma']


// export const ChatWebSocketIpOnline = "wss://kamikasi.net/llm/chat/"

export const ChatWebSocketIp ="wss://kamikasi.net/llm/chat/" 

// hello  项目
export  const HelloWebSocketIp = "wss://kamikasi.net/rtc/ws_server/"


// 隐私协议 html  string版本
export  const Privacy = ``

// 限制上传文件格式

export const  isAllowedType = [
   
   
   
   
    'image/jpeg',                 // JPEG 和 JPG 图片
    'image/png',                  // PNG 图片
    
    
    
    
  ]


  export const isNotAllowedImageType = [

    'application/pdf',            // PDF 文件
    'application/msword',         // DOC 文件
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // DOCX 文件
    'text/plain',                 // TXT 文件
    'application/vnd.ms-excel',   // XLS 文件
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // XLSX 文件
    'text/csv',                   // CSV 文件
    'application/csv'             // 另一种 CSV 文件的 MIME 类型

  ]

  export function  TranslatedMessage (title) {
    return this.$t(title);
  }


  export  function getBrowserTimeZone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }