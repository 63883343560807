import request from '../utils/request'
const base_url='/accounts/api'

export function login(data) {
  return request({
    url: base_url + '/login/',
    method: 'post',
    data
  })
}

export function register(data) {
  return request({
    url: base_url+'/register/',
    method: 'post',
    data
  })
}

// 发送验证码 
export function sendEmailCode(data) {
  return request({
    url: base_url+'/send_verification_code/ ',
    method: 'post',
    data
  })

}

// 退出登录
export function sendLoginOut (data) {
  return request({
    url: base_url+'/logout/',
    method: 'post',
    data
  })
}

// 注册手机号码  发送

export function SendsmsCode (data) {
  return request({
    url: base_url+'/send_sms_code/',
    method: 'post',
    data
  })
}


// 登录手机号码  发送
export function PhoneLoginSend (data) {
  return request({
    url: base_url+'/send_sms4login_code/',
    method: 'post',
    data
  })
}

