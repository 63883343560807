<template>
  <div class="top-nav-bar">
    <div class="nav-box">
      <div class="nav-left">
        <div class="left-icon" @click="showMenu = !showMenu">
          <van-icon name="list-switching" v-if="!showMenu" />
          <van-icon name="close" v-else />
        </div>
      </div>
      <div class="nav-middle"></div>
      <!-- <div class="nav-right" @click="$router.push('/login')">登录</div> -->
      <div class="menu-box">
        <LanguageDropdown />
      </div>
      <div class="menu-box">
        <div class="nav-right" @click="$router.push('/login')" v-if="!username">
          {{ $t("login") }}
        </div>

        <UserSettingDropdown v-else />
      </div>
    </div>

    <van-popup v-model="showMenu" class="popStyle" position="left">
      <MenuBar @onCloseHandle="onCloseHandle" />
    </van-popup>
  </div>
</template>

<script>
import MenuBar from "./MenuBar.vue";
import LanguageDropdown from "@/components/tools/TopMenuComp/LanguageDropdown.vue";
import UserSettingDropdown from "@/components/tools/TopMenuComp/UserSettingDropdown.vue";

export default {
  components: {
    MenuBar,
    LanguageDropdown,
    UserSettingDropdown,
  },
  data() {
    return {
      showMenu: false,
      username: "",
    };
  },

  mounted() {
    this.username = this.$AuthFunc.getUserName();
    console.log(
      "===username====",
      Boolean(this.username),
      this.$AuthFunc.getUserName()
    );
  },
  methods: {
    onCloseHandle() {
      this.showMenu = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-box {
  background-color: #25292e;

  padding: 15px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: #fff;

  .left-icon {
    font-size: 30px;
  }

  .nav-middle {
  
  }
}

.popMenuBox {
  width: 90%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  padding: 15px 0;
  border-radius: 10px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
  z-index: 2002;
}

.popStyle {
  width: 90%;
  height: 100%;
  margin-top: 64px;
}

.van-overlay {
  top: 64px;
}
</style>
