<template>
  <div class="bottom-box">
    <!-- v-if="!$AuthFunc.isMobile()" -->
    <BlackBottomList/>
    <div class="bottom-content">
     
      <img
        id="logo-img"
        :src="$i18n.locale=='zh'?ZnlogoSrc:EnglishlogoSrc"
        alt="default"
        width="120"
      />
      <div class="icp-box">
        <div class="icp-code">苏ICP备2023033387号</div>
        <div>Copyright ©2022-2024 Kamikasi Tech Ltd. All Rights Reserved</div>
      </div>
    </div>
  </div>
</template>

<script>
import IconSvg from "./IconSvg.vue";
// 页脚list
import BlackBottomList from "./BlackBottomList.vue";

export default {
  props: {
    fromway: {
      type: String,
      default: "",
    },
  },
  components: {
    IconSvg,
    BlackBottomList
  },
  data() {
    return {
      ZnlogoSrc: require("@/assets/code/logo-cn.png"),
      EnglishlogoSrc: require("@/assets/code/logo-en.png"),
      AboutList: [
        {
          title: "关于我们",
          itemList: [
            {
              text: "企业介绍",
              value: "companyIntro",
              url: "/article/company",
              mobileUrl: "/mobile/article/company",
            },
          ],
        },
      ],
    };
  },
  mounted() {
    // console.log("获取的值====：", this.fromway)
  },

  computed: {
    UserhrefLinkSource() {
      return this.$i18n.locale === "zh"
        ? "/web/user-zh.html"
        : "/web/user-en.html";
    },
    PrivacyhrefLinkSource() {
      return this.$i18n.locale === "zh"
        ? "/web/yinsi-zh.html"
        : "/web/yinsi-en.html";
    },
  },
  methods: {
    openNewPage(e) {
      const routeUrl = this.$router.resolve({ path: e });
      window.open(routeUrl.href, "_blank");
    },
    gotoDetail(item) {
      if (this.fromway) {
        if (item.type) {
          this.$router.push(`${item.mobileUrl}/${item.value}`);
        } else {
          this.$router.push(item.mobileUrl);
        }
      } else {
        this.$router.push(`${item.url}/${item.value}`);
      }
    },
  },
};
</script>
<style scoped lang="scss">

.bottom-box {
  padding-top: 30px;
  background-color: #000;
  color: #fff;
}

.bottom-content {
  display: flex;
 
  align-items: center;
  justify-content: center;
  padding: 30px;
}
#logo-img {
  margin-right: 20px;
}

.icp-box {
  font-size: 12px;
}

.jinghui {
  font-size: 12px;
  color: #ccc;
  display: inline-block;
  margin-top: 6px;
  padding-left: 20px;
  height: 16px;
  line-height: 16px;
  background: url(https://mbdp01.bdstatic.com/static/landing-pc/img/icon_police.7296bdfd.png)
    0 0 no-repeat;
}












</style>
