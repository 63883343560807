import router from "@/router";
import axios from "axios";
// import Message from 'ant-design-vue/es/message'
import AuthFunc from './auth'
import i18n from "../../src/i18n";

axios.defaults.withCredentials = true;
// create an axios instance
const request = axios.create({
  // baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  baseURL: "/",
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 60000, // request timeout
});
// localStorage.getItem("KmksToken")
// request interceptor
request.interceptors.request.use(
  (config) => {
    let KmksToken =  AuthFunc.getToken() || "";
   
    let USERUUID = localStorage.getItem("USERUUID") || "";
    

    // let token = '';
    if (KmksToken) {
      config.headers["Authorization"] = KmksToken;
    }

    config.headers["USERUUID"] = USERUUID || "";
    config.headers["currentLanguage"] = i18n.locale // 当前语言
    // const cookie = document.cookie
    return config;
  },
  (error) => {
    // do something with request error
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

// response interceptor
request.interceptors.response.use(
  (response) => {
    const res = response;
   
    // 400 用户未登录
    if (res.data.code == 401) {
     
      localStorage.clear()
      router.replace({
        path: "/login",
        // query: { redirect: router.currentRoute.fullPath },
      })
       
    }

    // if the custom code is not 20000, it is judged as an error.
    return res;
    // if (res.code !== '200') {
    //return Promise.reject(new Error(res.message || 'Error'))
    // return res
    // } else {
    // return res
    // }
  },
  (error) => {
    console.log("err", error); // for debug

    if (error.response) {
      switch (error.response.status) {
        case 401:
          router.replace({
            path: "/login",
          });
      }
    }

    return Promise.reject(error);
  }
);

export default request;
