import router from "./router";
import AuthFunc from './utils/auth'

// 跳转路由前执行的钩子函数
// to: 即将要进入的路由对象,from: 当前导航正要离开的路由,next:下一步执行的方法，参数是包含跳转路径的对象
router.beforeEach((to, from, next) =>{
  const hasToken = AuthFunc.getToken()

    //  判断是否需要登录
    if(to.meta.authRequired){
      if(hasToken){
        next();
      }else{
        next({
          path: '/login',
          //  把当前页面的路由path作为参数传递给登录页面，登录成功后跳转到去往的页面
          query: {redirect: to.fullPath}
        })
      }
    }else {
      //  没有要求登录，直接跳转
      next();
    }
  })
  
