import request from '../utils/request'


export function deleteImg(data) {
    return request({
      url: '/llm/delete/',
      method: 'post',
      data
    })
  }


  export function deleteToolsSource(data) {
    return request({
      url: '/accounts/delete/',
      method: 'post',
      data
    })
  }




export function chatTalk (data) {
    return request({
        url: '/chat/api_qwen2/',
        method: 'post',
        data
      })
}


// 获取对话历史记录
export function getchatHistory (data) {
  return request ({
    url: '/chat/conversation_history/?theme_id='+ data.theme_id,
    method: 'get'
  })
}

// 获取主题
export function getThemeItem (data) {
  return request ({
    url: '/chat/conversation_history/?uuid='+ data.uuid,
    method: 'get'
  })
}

// 删除对话
export function deleteChatList(data) {
  return request ({
    url: 'chat/delete_conversation/',
    method: 'post',
    data
  })
}

// 删除各种上传资源
export function deleteRtcSource(data) {
  return request({
    url: '/accounts/delete/',
    method: 'post',
    data
  })
  
}


// 图片查询
export function getCurrentImg (data) {

  return request ({
    url: '/rtc/get_images/',
    method: 'post',
    data
  })


}

// 判断是否是国内还是国外

export function  getCurrentCountry (data) {
  return request ({
    url: '/accounts/get_country/',
    method: 'post',
    data
  })
}




































