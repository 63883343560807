<template>
  <div class="user-setting-box">
    <el-dropdown trigger="click" style="color: #fff">
      <span class="el-dropdown-link">
        <div class="link-box">
          <i
            class="el-icon-user-solid"
            style="font-size: 18px; margin-right: 10px"
          />
          {{ username }}<i class="el-icon-arrow-down el-icon--right"></i>
        </div>
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item @click.native="$router.push('/account/setting')"> {{ $t("Setting.Profile") }} </el-dropdown-item>
        <el-dropdown-item @click.native="$router.push('/password/find')">
          {{ $t("Setting.Password") }}
        </el-dropdown-item>
        <el-dropdown-item @click.native="outHandle()">{{
          $t("logout")
        }}</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import { sendLoginOut } from "@/api/loginApi";
export default {
  data() {
    return {
      username: "",
    };
  },
  mounted() {
   this.username = this.$AuthFunc.getUserName();
  
  
  },

  methods: {
    outHandle() {
      sendLoginOut({}).then((res) => {
        if (res.data.code == 200) {
          localStorage.clear();
          this.username = "";
          // this.$message.success("用户已退出");
          this.$router.push("/login");
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.link-box {
  display: flex;
  align-items: center;
  // margin-right: 20px;
}
.menu-action-box {
  display: flex;
  .top-menu-login {
    cursor: pointer;
    padding: 0 10px;
    font-size: 16px;
  }
}

.top-menu-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #111111;
  color: #fff;
  height: 80px;
  line-height: 80px;
  font-size: 22px;
  padding: 0 5% 0 20px;

  .menu-right {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .top-menu-item {
    width: 120px;
    text-align: center;
    cursor: pointer;
    &:hover {
      color: antiquewhite;
    }
  }
}

.logoStyle {
  width: 220px;

  padding: 12px 0 12px 12px;
  height: 55px;
}

.EnglishLogoStyle {
  width: 220px;
  padding: 12px 0 12px 12px;
}
</style>

