<template>
  <div id="app">
   
    <router-view />
  </div>
</template>

<script>

import { getBrowserTimeZone } from './utils/constants'
import { getCurrentCountry } from './api/toolApi'
export default {
  name: "App",
  components: {},
  data() {
    return {
      currentTime: "", // 当前时间
      chinaTime: "", // 中国时间
      accessLocation: "", // 访问地
      timezone: getBrowserTimeZone()
    };
  },

  mounted() {

    


    this.checkAccessLocation();

    if (this.$AuthFunc.isMobile()) {
      this.$router.replace("/mobile/index");
    } else {
      console.log("pc端");
    }
  },

  methods: {
    checkAccessLocation() {
      var data = {
        timezone : this.timezone
      }

      getCurrentCountry(data).then(res=>{
        var data = res.data
        if (data.code == 200) {

          this.accessLocation = data.country_name || "Abroad"


        }else {
          console.log(res)
        }

        sessionStorage.setItem("LocalCountry", this.accessLocation)
      })





 
 
 
 





 
 

   
   
   
   
   
   
   

    //  sessionStorage.setItem("LocalCountry", this.accessLocation)


    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
