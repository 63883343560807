<template>
  <div class="language-dropdown-box">
    <el-dropdown trigger="click" style="color: #fff">
      <span class="el-dropdown-link">
        <div class="link-box">
          <img
            src="@/assets/code/guojihua.png"
            style="width: 18px; height: 18px; margin-right: 10px"
          />
          {{ currentLang == "zh" ? $t("language") : "English"
          }}<i class="el-icon-arrow-down el-icon--right"></i>
        </div>
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item @click.native="switchHandle('zh')"
          >简体中文</el-dropdown-item
        >
        <el-dropdown-item @click.native="switchHandle('en')"
          >English</el-dropdown-item
        >
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentLang: this.$i18n.locale,
    };
  },
  methods: {
    switchHandle(value) {
      this.$store.commit("setUpdateLang", value);
      this.$i18n.locale = value;
      this.currentLang = value;
      localStorage.setItem("language", value);
    },
  },
};
</script>

<style scoped lang="scss">

.language-dropdown-box ,
.link-box {
  display: flex;
  align-items: center;
  // margin-right: 20px;
}
.menu-action-box {
  display: flex;
  .top-menu-login {
    cursor: pointer;
    padding: 0 10px;
    font-size: 16px;
  }
}

.top-menu-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #111111;
  color: #fff;
  height: 80px;
  line-height: 80px;
  font-size: 22px;
  padding: 0 5% 0 20px;

  .menu-right {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .top-menu-item {
    width: 120px;
    text-align: center;
    cursor: pointer;
    &:hover {
      color: antiquewhite;
    }
  }
}

.logoStyle {
  width: 220px;

  padding: 12px 0 12px 12px;
  height: 55px;
}

.EnglishLogoStyle {
  width: 220px;
  padding: 12px 0 12px 12px;
}
</style>

