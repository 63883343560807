import Vue from "vue";
import Router from "vue-router";
import Layout from "../layouts/LayoutsVue.vue";
import MobileLayout from "../layouts/MobileLayouts.vue";

import i18n from "../../src/i18n";

Vue.use(Router);

// 重写 push replace方法
const originalPush = Router.prototype.push;

let originReplace = Router.prototype.replace;

//修改原型对象中的push方法
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Router.prototype.replace = function push(location) {
  return originReplace.call(this, location).catch((err) => err);
};

const traditionalRoutes = [
  // {
  // path: "/",
  // redirect: "/system", // 重定向到 /tool
  // },
  // 登录
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/Login/index"),
    meta: { title: "登录", icon: "table", titleKey: "login" },
  },
  //注册
  {
    path: "/register",
    name: "register",
    component: () => import("@/views/Register/index.vue"),
    meta: { title: "注册", icon: "table", titleKey: "register" },
  },

  {
    path: "/password/find",
    name: "passwordfind",
    component: () => import("@/views/Password/find.vue"),
    meta: { title: "找回密码", icon: "table", titleKey: "password" },
  },
  {
    path: "/about/kmks",
    name: "aboutkmks",
    component: () => import("@/views/About/kmks"),
  },
];

const MobileRoutes = [
  {
    path: "/mobile/login",
    name: "mobileLogin",
    component: () => import("@/views/Login/index"),
    meta: { title: "登录", icon: "table", titleKey: "login" },
  },
  {
    path: "/mobile/index",
    name: "LayoutIndex",
    component: MobileLayout,
    children: [
      {
        path: "/mobile/index",
        name: "mobileindex",
        component: () => import("../views/MobileTool/Home/HomeIndex.vue"),
        meta: {
          title: "首页",
          icon: "table",
          titleKey: "firstIndex",
          IsMobile: true,
        },
      },
      {
        path: "/mobile/talk",
        name: "mobiletalk",
        component: () => import("../views/MobileTool/Chat/MobileChat.vue"),
        meta: {
          title: "AI 文本",
          icon: "documentation",
          titleKey: "siderMenu.AIWritting",
          IsMobile: true,
        },
      },

      {
        path: "/mobile/voice",
        name: "MobileVoiceMake",
        component: () => import("@/views/MobileTool/Voice/MobileVoice.vue"),
        meta: {
          title: "AI语音",
          keepAlive: true,
          titleKey: "siderMenu.AIvoice",
          IsMobile: true,
        },
      },

      {
        path: "/mobile/video/aicharacter",
        name: "mobileaicharacter",
        component: () =>
          import("@/views/MobileTool/AiCharacter/MobileAiCharacter.vue"),
        meta: {
          title: "AI人物",
          icon: "table",
          keepAlive: true,
          IsMobile: true,
          titleKey: "siderMenu.AICharacter",
        },
      },

      {
        path: "/mobile/video/aimake",
        name: "mobileaimake",
        component: () =>
          import("@/views/MobileTool/AiVideoMake/AiVideoMobileMake.vue"),
        meta: {
          title: "AI视频模块制作",
          icon: "tree",
          keepAlive: true,
          titleKey: "siderMenu.VideoMake",
          IsMobile: true,
        },
      },

      {
        path: "/mobile/video/customavatar",
        name: "mobilecustomavatar",
        component: () =>
          import("@/views/MobileTool/MobileCustoMade/MobileCustoMade.vue"),
        meta: {
          title: "Avatar制作",
          icon: "table",
          keepAlive: true,
          IsMobile: true,
          titleKey: "siderMenu.AIAvatar",
        },
      },

      {
        path: "/mobile/about/kmks",
        name: "aboutkmks",
        component: () => import("@/views/MobileTool/About/MobileAbout.vue"),
        meta: {
          title: "公司简介",
          icon: "table",
          keepAlive: true,
          IsMobile: true,
          titleKey: "siderMenu.AIAvatar",
        },
      },

      // 移动端用户媒体库
      {
        path: "/mobile/user/voice",
        name: "mobileuservoice",
        component: () =>
          import("@/views/MobileTool/UserManage/MobileUserVoice.vue"),
        meta: {
          title: "音频数据",
          icon: "table",
          authRequired: true,
          IsMobile: true,
          titleKey: "MediaLibrary.AudioData",
        },
      },
      {
        path: "/mobile/user/video",
        name: "mobileuservideo",
        component: () =>
          import("@/views/MobileTool/UserManage/MobileUserVideo.vue"),
        meta: {
          title: "视频数据",
          titleKey: "MediaLibrary.VideoData",
          icon: "table",
          keepAlive: true,
          IsMobile: true,
          authRequired: true,
        },
      },
    ],
  },
  // {
  // path: "/mobile/voice",
  // component: MobileLayout,
  // children: [
  // {
  // path: "/mobile/voice",
  // name: "MobileVoiceMake",
  // component: () => import("@/views/MobileTool/Voice/MobileVoice.vue"),
  // meta: {
  // title: "AI语音",
  // keepAlive: true,
  // titleKey: "siderMenu.AIvoice",
  // }
  // }
  // ]
  // }
];

const ToolSystemRoutes = [
  {
    path: "/",
    component: Layout,
    // redirect: "/system/pipleline",
    name: "Example",
    // meta: { title: "Example", icon: "el-icon-s-help" },
    children: [
      {
        path: "/",
        name: "Pipleline",
        component: () => import("@/views/Tool/pipeLine.vue"),
        meta: { title: "工具网站", icon: "table", titleKey: "WebName" },
      },
      {
        path: "/price",
        name: "Price",
        component: () => import("@/views/Tool/PriceVue.vue"),
        meta: { title: "价格", icon: "tree", titleKey: "topMenu.menu03" },
      },

      {
        path: "/stripe/pay",
        name: "stripepay",
        component: () => import("@/views/Tool/StripeSamples/StripePay.vue"),
        meta: { title: "stripe支付", icon: "tree" },
      },

      {
        path: "/stripe/success",
        name: "stripesuccess",
        component: () => import("@/views/Tool/StripeSamples/StripeSuccess.vue"),
        meta: { title: "stripe支付信息", icon: "tree" },
      },
      {
        path: "/stripe/cancel",
        name: "stripecancel",
        component: () => import("@/views/Tool/StripeSamples/StripeCancel.vue"),
        meta: { title: "取消支付", icon: "tree" },
      },



      // 账号设置
      {
        path: "/account/setting",
        name: "accountsetting",
        component: () => import("@/views/Tool/Setting/AccountSetting.vue"),
        meta: {
          title: "账号设置",
        },
      },
    ],
  },

  {
    path: "/talk",
    component: Layout,
    children: [
      {
        path: "/talk",
        name: "talk",
        component: () => import("@/views/Tool/ChatVue.vue"),
        meta: {
          title: "AI 文本",
          icon: "documentation",
          keepAlive: true,
          titleKey: "siderMenu.AIWritting",
        },
      },
    ],
  },

  // 直播   直播推流

  {
    path: "/live",
    component: Layout,
    children: [
      {
        path: "/live/stream",
        name: "Live",
        component: () => import("@/views/Tool/Live/LiveStream.vue"),
        meta: { title: "直播", icon: "table" ,keepAlive: false},
      },
    ],
  },

  {
    path: "/highlevel",
    component: Layout,
    children: [
      {
        path: "/highlevel",
        name: "highlevel",
        component: () => import("@/views/Tool/HighLevel/HighLevel.vue"),
        meta: { title: "高级定制", icon: "documentation" },
      },
    ],
  },
  {
    path: "/voice",
    component: Layout,
    children: [
      {
        path: "/voice",
        name: "VoiceMake",
        component: () => import("@/views/Tool/Voice/VoiceMake.vue"),
        meta: {
          title: "AI语音",
          keepAlive: true,
          titleKey: "siderMenu.AIvoice",
        },
      },
    ],
  },
  {
    path: "/video",
    component: Layout,
    redirect: "/video/avatarmake",
    name: "Example",
    meta: { title: "Example", icon: "el-icon-s-help" },
    children: [
      {
        path: "/video/avatarmake",
        name: "avatarmake",
        component: () => import("@/views/Tool/Video/AvatarMake.vue"),
        meta: {
          title: "AI人物",
          icon: "table",
          keepAlive: true,
          titleKey: "siderMenu.AICharacter",
        },
      },
      {
        path: "/video/customavatar",
        name: "customavatar",
        component: () => import("@/views/Tool/CustomMade/UsercustomAvatar.vue"),
        meta: {
          title: "Avatar制作",
          icon: "table",
          keepAlive: true,
          titleKey: "siderMenu.AIAvatar",
        },
      },
      {
        path: "/video/aimake",
        name: "aimake",
        component: () => import("@/views/Tool/Video/AiModuleMake.vue"),
        meta: {
          title: "AI视频模块制作",
          icon: "tree",
          keepAlive: true,
          titleKey: "siderMenu.VideoMake",
        },
      },
      {
        path: "/video/translate",
        name: "translate",
        component: () => import("@/views/Tool/Video/VideoTranslate.vue"),
        meta: { title: "视频翻译", icon: "tree", keepAlive: true },
      },
    ],
  },

  {
    path: "/user",
    component: Layout,
    redirect: "/user/voice",
    name: "用户媒体库",
    meta: {
      title: "Example",
      icon: "el-icon-s-help",
      titleKey: "MediaLibrary.themeTitle",
    },
    children: [
      {
        path: "/user/voice",
        name: "uservoice",
        component: () => import("@/views/Tool/UserManage/UserVoice.vue"),
        meta: {
          title: "音频数据",
          icon: "table",
          authRequired: true,
          titleKey: "MediaLibrary.AudioData",
        },
      },
      {
        path: "/user/video",
        name: "uservideo",
        component: () => import("@/views/Tool/UserManage/UserVideo.vue"),
        meta: {
          title: "视频数据",
          titleKey: "MediaLibrary.VideoData",
          icon: "table",
          keepAlive: true,
          authRequired: true,
        },
      },
      {
        path: "/user/image",
        name: "userimage",
        component: () => import("@/views/Tool/UserManage/UserImage.vue"),
        meta: {
          title: "图片数据",
          icon: "table",
          authRequired: true,
          titleKey: "MediaLibrary.PictureData",
        },
      },
    ],
  },
];

const router = new Router({
  mode: "history",
  routes: [...traditionalRoutes, ...MobileRoutes, ...ToolSystemRoutes],
});

router.beforeEach((to, from, next) => {
  if (to.meta && to.meta.title) {
    if (to.meta.titleKey) {
      document.title = i18n.t(to.meta.titleKey);
    } else {
      document.title = to.meta.title;
    }
  }
  next();
});

export default router;
